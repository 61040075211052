import axios, { AxiosError } from 'axios';
import { destroyCookie } from 'nookies';
import { jwtDecode } from 'jwt-decode';
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
  COOKIE_USER_SESSION,
} from '../constants';
import { getAccountsURL } from '../../apps/accounts/routes';

interface DecodedToken {
  exp: number;
  user_id: string;
  email: string;
  client: string;
  username: string;
}

export type UserDetail = {
  userId: string;
  username: string | null;
  email: string;
  isSuperUser: boolean;
  personId: string | null;
  client: string;
};

interface FetcherConfigParams {
  token?: string;
  actAsClientId?: string;
}

interface FetcherConfig {
  headers: {
    Authorization: string;
    ActAs?: string;
  };
}

export function validateAndDecodeUser(
  accessToken?: string | null,
  refreshToken?: string | null,
  userSessionDetails?: string | null
) {
  if (!accessToken || !refreshToken || !userSessionDetails) {
    destroyTokens();
    return { isValid: false } as const;
  }

  let userDetails;

  try {
    userDetails = JSON.parse(userSessionDetails);
  } catch {
    return { isValid: false } as const;
  }

  const decodedToken = jwtDecode<DecodedToken>(accessToken);
  const currentTime = Date.now() / 1000;
  const isExpired = decodedToken.exp < currentTime;

  if (isExpired) {
    destroyTokens();
    return { isValid: false } as const;
  }

  return {
    isValid: true,
    accessToken,
    refreshToken,
    userDetails,
  } as const;
}

export function destroyTokens() {
  const cookieOptions = {
    path: '/',
  };

  destroyCookie({}, COOKIE_ACCESS_TOKEN, cookieOptions);
  destroyCookie({}, COOKIE_REFRESH_TOKEN, cookieOptions);
  destroyCookie({}, COOKIE_USER_SESSION, cookieOptions);
}

export function getFetcherConfig({
  token,
  actAsClientId,
}: FetcherConfigParams) {
  const config: FetcherConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (actAsClientId) {
    config.headers.ActAs = actAsClientId;
  }

  return config;
}

export async function validateServerSideToken(token: string) {
  try {
    console.log('getAccountsURL()', getAccountsURL());

    await axios.get(getAccountsURL(), getFetcherConfig({ token }));

    return { isTokenValidOnServer: true };
  } catch (error) {
    if (error instanceof AxiosError) {
      console.log('validateServerSideToken error (109)', error?.response?.data);
    }

    if (error instanceof Error) {
      console.log('validateServerSideToken error (113)', error?.message);
    }

    return { isTokenValidOnServer: false };
  }
}
