import DrawerItem from '../DrawerItem/DrawerItem';
import { MenuItem } from './drawerLinksConfig';
import { getRootDomainClient } from '@repo/config/generalUtils';

interface RenderDrawerLinkProps {
  link: MenuItem;
  depth?: number;
}

export default function renderDrawerLink({
  link,
  depth = 0,
}: RenderDrawerLinkProps) {
  const { label, getIcon, href, children } = link;

  const prefixedHref = href
    ? `${getRootDomainClient()}${href}`
    : 'javascript:void(0)';

  const renderChildren = (children: Partial<MenuItem>[], childDepth: number) =>
    children
      .filter((child): child is MenuItem => child.id !== undefined) // Type guard
      .map((child) => (
        <RenderChildLink key={child.id} child={child} depth={childDepth} />
      ));

  return (
    <DrawerItem
      key={label}
      expandable={Array.isArray(children) && children.length > 0}
      label={label}
      getIcon={getIcon}
      href={prefixedHref}
      depth={depth}
    >
      {Array.isArray(children) && children.length > 0
        ? renderChildren(children, depth + 1)
        : null}
    </DrawerItem>
  );
}

// Sub-function for rendering child links recursively
function RenderChildLink({ child, depth }: { child: MenuItem; depth: number }) {
  const { label, getIcon, href, children } = child;
  const prefixedHref = href ? `${getRootDomainClient()}${href}` : '';

  return (
    <DrawerItem
      key={child.id}
      expandable={Array.isArray(children) && children.length > 0}
      label={label}
      getIcon={getIcon}
      href={prefixedHref}
      depth={depth}
    >
      {Array.isArray(children) && children.length > 0
        ? children.map((grandChild) => (
            <RenderChildLink
              key={grandChild.id}
              child={grandChild}
              depth={depth + 1}
            />
          ))
        : null}
    </DrawerItem>
  );
}
