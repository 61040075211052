import { useField, useFormikContext } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

interface FormikDateTimePickerFieldProps {
  label: string;
  customOnChange?: (date: string) => void;
  name: string;
  required?: boolean;
  disabled?: boolean;
}

export default function FormikDateTimePickerField({
  label,
  name,
  customOnChange,
  required,
  disabled,
}: FormikDateTimePickerFieldProps) {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDateTimePicker
        orientation='landscape'
        name={field.name}
        value={field.value}
        label={label}
        disabled={disabled}
        slotProps={{
          textField: {
            size: 'small',
            required,
            fullWidth: true,
          },
        }}
        onChange={(newValue) => {
          setFieldValue(field.name, newValue);
          if (customOnChange) customOnChange(newValue);
        }}
      />
    </LocalizationProvider>
  );
}
