import React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@repo/config/theme';
import AdminLayout from '@repo/ui/AdminLayout';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { store } from '@repo/redux/store';
import { useAppDispatch } from '@repo/redux/hooks';
import { setProfile } from '@repo/redux/profileSlice';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DEFAULT_QUERY_CLIENT_OPTIONS,
  TOAST_CONTAINER_PROPS,
} from '@repo/config/constants';

const noAdminLayoutRoutes = [
  '/auth/sign-in',
  '/auth/sign-up',
  '/auth/forgot-password',
  '/auth/reset-password/[token]',
  '/404',
];

const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS);

function App(props: AppProps) {
  const router = useRouter(); // Use the useRouter hook to get current route info
  const dispatch = useAppDispatch();

  const { Component, pageProps } = props;
  const { accessToken, refreshToken, userDetails, domain, _clientOptions } =
    pageProps;

  // Check if the current route is in the noAdminLayoutRoutes array
  const useAdminLayout = !noAdminLayoutRoutes.includes(router.pathname);

  React.useEffect(() => {
    if (accessToken && refreshToken && userDetails) {
      dispatch(
        setProfile({
          domain,
          accessToken,
          refreshToken,
          userId: userDetails?.userId,
          username: userDetails?.username,
          email: userDetails?.email,
          isSuperUser: userDetails?.isSuperUser,
          personId: userDetails?.personId,
          client: userDetails?.client,
          roleNames: userDetails?.roleNames,
          employeeId: userDetails?.details?.employee?.id,
          employeeSupervisorId:
            userDetails?.details?.employee?.department_heads?.id,
          employeeSupervisorName:
            userDetails?.details?.employee?.department_heads?.label,
          adminLogo: _clientOptions?.adminLogo,
          favicon: _clientOptions?.favicon,
          brandColor: _clientOptions?.brandColor,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, refreshToken, userDetails, domain]);

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <QueryClientProvider client={queryClient}>
          {useAdminLayout ? (
            <AdminLayout>
              <Component {...pageProps} />
            </AdminLayout>
          ) : (
            <Component {...pageProps} />
          )}

          <ToastContainer {...(TOAST_CONTAINER_PROPS as ToastContainerProps)} />
        </QueryClientProvider>
      </ThemeProvider>
    </AppCacheProvider>
  );
}

export default function AppWrapper(props: AppProps) {
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  );
}
