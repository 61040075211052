import axios, { AxiosError } from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid2';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useMutation } from '@tanstack/react-query';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import FormikAutocompleteField from '@repo/ui/Inputs/AutocompleteField/FormikAutocompleteField';
import FormikDateTimePickerField from '@repo/ui/Inputs/DateTimePickerField/FormikDateTimePickerField';
import FormikTextField from '@repo/ui/Inputs/TextField/FormikTextField';
import { getDayOffTypeURL, getDayOffURL } from '@repo/config/apps/hr/routes';
import { useAppSelector } from '@repo/redux/hooks';
import { selectProfile } from '@repo/redux/profileSlice';
import { getFetcherConfig } from '@repo/config/authUtils';
import { handleServerValidationErrors } from '@repo/config/formikUtils';
import { AutocompleteOption } from '@repo/ui/Inputs/AutocompleteField/AutocompleteField';
import moment, { Moment } from 'moment';

interface RequestDaysOffPopupProps {
  open: boolean;
  setOpen: (o: boolean) => void;
}

// Define the shape of the form values object
interface FormValues {
  type?: AutocompleteOption | null;
  notes: string | null;
  days_requested: number;
  days_off_from: Moment;
}

// Initial values for the form fields
const initialValues: FormValues = {
  type: undefined,
  notes: '',
  days_requested: 0.5,
  days_off_from: moment(),
};

// Defining the validation schema using Yup for each field in the form
const validationSchema = Yup.object().shape({
  type: Yup.mixed(),
  notes: Yup.string(),
  days_requested: Yup.number()
    .positive('Must be > 0')
    .required('This field is required.')
    .test(
      'is-whole-or-half',
      'Value must be a whole number or a half increment (e.g., 0.5, 1.5).',
      (value) => value % 1 === 0 || value % 1 === 0.5
    ),
  days_off_from: Yup.date()
    .typeError('Please select a date.')
    .required('This field is required.'),
});

export default function RequestDaysOffPopup({
  open,
  setOpen,
}: RequestDaysOffPopupProps) {
  const { accessToken, employeeId, employeeSupervisorId } =
    useAppSelector(selectProfile);

  const mutation = useMutation({
    mutationFn: (newApplication: object) => {
      return axios.post(
        getDayOffURL(),
        newApplication,
        getFetcherConfig({ token: accessToken })
      );
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Request Days Off
        </DialogTitle>
      </Box>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setErrors, setFieldError }
          ) => {
            try {
              setSubmitting(true);

              const { type, ...rest } = values;

              if (!type?.id) {
                setFieldError('type', 'This field is required.');
                return;
              }

              if (!employeeId || !employeeSupervisorId) {
                toast.error('Employee Department not setup correctly.');
                return;
              }

              await mutation.mutateAsync({
                ...rest,
                type: type?.id,
                employee: employeeId,
                approved_by: employeeSupervisorId,
              });

              resetForm();
              setOpen(false);
              toast.success('Operation completed');
            } catch (error) {
              if (
                error instanceof AxiosError &&
                error?.response?.data?.days_off_from
              ) {
                setFieldError(
                  'days_requested',
                  error?.response?.data?.days_off_from
                );
                return;
              }
              handleServerValidationErrors({
                error,
                values,
                setErrors,
                onError: (msg) => toast.error(msg),
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete='off'>
              <Grid sx={{ paddingBottom: '10px' }} container spacing={3}>
                <Grid size={12}>
                  <Box sx={{ width: '50%' }}>
                    <FormikAutocompleteField
                      name='type'
                      label='Type'
                      requestKey={['day-off-type']}
                      fetchUrl={getDayOffTypeURL}
                      renderRow={(row) => ({
                        id: row?.id,
                        label: row?.name,
                      })}
                      urlParams={`&ordering=created_at`}
                      required
                    />
                  </Box>
                </Grid>

                <Grid size={6}>
                  <FormikDateTimePickerField
                    name='days_off_from'
                    label='First Day of Leave'
                    required
                  />
                </Grid>

                <Grid size={6}>
                  <FormikTextField
                    size='small'
                    fullWidth
                    name='days_requested'
                    label='Number of Days'
                    required
                  />
                </Grid>

                <Grid size={12}>
                  <FormikTextField
                    size='small'
                    fullWidth
                    label='Notes'
                    name='notes'
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type='submit' disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
