import Link from 'next/link';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RequestDaysOffPopup from '../RequestDaysOffPopup/RequestDaysOffPopup';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

interface AppBarQuickToolsMenuProps {
  domain: string;
}

export default function AppBarQuickToolsMenu({
  domain,
}: AppBarQuickToolsMenuProps) {
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorElement);
  const [isDaysOffPopupOpen, setDaysOffPopupOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const handleOpenDaysOffPopup = () => {
    setDaysOffPopupOpen(true);
    handleMenuClose();
  };

  const handleReportBug = () => {
    handleMenuClose();
    window.location.href = `${domain}/svc/bugs?create=true`;
  };

  return (
    <Box sx={{ ml: 1, display: 'flex' }}>
      <IconButton
        color='inherit'
        id='app-bar-quick-tools-button'
        aria-controls={isMenuOpen ? 'app-bar-quick-tools-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup='true'
        size='small'
        onClick={handleMenuOpen}
      >
        <OpenInNewIcon fontSize='inherit' />
      </IconButton>

      <StyledMenu
        id='app-bar-quick-tools-menu'
        MenuListProps={{
          'aria-labelledby': 'app-bar-quick-tools-button',
        }}
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleReportBug} disableRipple>
          Report Bug
        </MenuItem>
        <MenuItem onClick={handleOpenDaysOffPopup} disableRipple>
          Request Days Off
        </MenuItem>
      </StyledMenu>

      <RequestDaysOffPopup
        open={isDaysOffPopupOpen}
        setOpen={setDaysOffPopupOpen}
      />
    </Box>
  );
}
