import { AxiosError } from 'axios';

interface HandleServerValidationErrorsProps {
  error: Error | AxiosError | any;
  values: { [key: string]: any };
  setErrors: any;
  onError: (error: string) => void;
}

export function handleServerValidationErrors({
  error,
  values,
  setErrors,
  onError,
}: HandleServerValidationErrorsProps) {
  const DEFAULT_ERROR_MESSAGE = 'There was an error, please try again';
  const MAINTENANCE_ERROR_MESSAGE =
    'Under Maintenance, please retry in a few minutes';

  try {
    if (!(error instanceof AxiosError)) {
      onError(DEFAULT_ERROR_MESSAGE);
      return;
    }

    const { status, data: errorDetails } = error.response || {};

    if (status === 400 && errorDetails) {
      const errorKeys = Object.keys(errorDetails);

      if (errorKeys.length === 0) {
        onError(DEFAULT_ERROR_MESSAGE);
        return;
      }

      const firstErrorKey = errorKeys[0] as keyof typeof errorDetails;
      const firstErrorValue = errorDetails[firstErrorKey];

      const isFirstErrorValueArray =
        Array.isArray(firstErrorValue) && firstErrorValue.length > 0;

      const firstStringInArray = isFirstErrorValueArray
        ? firstErrorValue.find((message) => typeof message === 'string')
        : null;

      const isFirstErrorValueString = typeof firstErrorValue === 'string';

      if (firstErrorKey in values) {
        setErrors(errorDetails);
      } else if (isFirstErrorValueArray && firstStringInArray) {
        onError(firstStringInArray);
      } else if (isFirstErrorValueString) {
        onError(firstErrorValue);
      } else {
        onError(DEFAULT_ERROR_MESSAGE);
      }
    } else if (status === 502) {
      onError(MAINTENANCE_ERROR_MESSAGE);
    } else {
      onError(DEFAULT_ERROR_MESSAGE);
    }
  } catch (unexpectedError) {
    onError(DEFAULT_ERROR_MESSAGE);
  }
}
