/**
 *
 * HR URLs
 */
const HR_HOST = process.env.NEXT_PUBLIC_HR_HOST;

export const getJobLevelURL = ({ query = '' } = {}) => {
  return `${HR_HOST}/api/job-levels/${query}`;
};

export const getEmployeeURL = ({ query = '' } = {}) => {
  return `${HR_HOST}/api/employees/${query}`;
};

export const getVacancyURL = ({ query = '' } = {}) => {
  return `${HR_HOST}/api/vacancies/${query}`;
};

export const getDayOffTypeURL = ({ query = '' } = {}) => {
  return `${HR_HOST}/api/day-off-types/${query}`;
};

export const getDayOffURL = ({ query = '' } = {}) => {
  return `${HR_HOST}/api/day-offs/${query}`;
};
