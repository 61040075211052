import { useState, useEffect, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useAutocomplete from '@repo/hooks/useAutocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export interface AutocompleteOption {
  id: string | null;
  label: string;
}

export type AutocompleteOptionValue =
  | AutocompleteOption
  | AutocompleteOption[]
  | null;

interface AutocompleteFieldProps {
  label: string;
  fetchUrl: (options: { query: string }) => string;
  error?: boolean;
  renderRow?: (data: any) => any; // Consider defining a more specific type based on actual data usage
  helperText?: string;
  requestKey: string[];
  initialValue?: AutocompleteOptionValue;
  customValue?: AutocompleteOption;
  customOnChange?: (newValue: AutocompleteOptionValue) => void;
  disableSearch?: boolean;
  getOptionLabel?: (option: AutocompleteOption) => string;
  required?: boolean;
  getData?: (data: any[]) => void; // Consider defining a more specific type
  urlParams?: string | undefined;
  disabled?: boolean;
  enabled?: boolean;
  orderingCol?: string;
  setCustomOptions?: (data: any[]) => any[]; // Consider defining a more specific type
  multiple?: boolean;
  autoFocus?: boolean;
  height?: string;
}

function getValidatedInput(
  value: AutocompleteOptionValue,
  inputValue: string,
  customValue: AutocompleteOption | undefined,
  customOnChange: ((newValue: AutocompleteOptionValue) => void) | undefined,
  disableSearch: boolean | undefined
): string {
  if (disableSearch) {
    return '';
  }

  // Helper function to check if the input value matches a given Value object's label
  const checkValueMatch = (val: AutocompleteOption) =>
    val && val.label === inputValue;

  if (Array.isArray(value)) {
    if (customOnChange && customValue && checkValueMatch(customValue)) {
      return '';
    }
  } else if (
    (customOnChange && customValue && checkValueMatch(customValue)) ||
    checkValueMatch(value as AutocompleteOption)
  ) {
    return '';
  }

  return inputValue || '';
}

function getInitialValue(
  initialValue: AutocompleteOptionValue | undefined,
  multiple: boolean | undefined
): AutocompleteOptionValue {
  if (!initialValue) {
    return multiple ? [] : null;
  }
  return initialValue;
}

export default function AutocompleteField({
  label,
  fetchUrl,
  error,
  renderRow,
  helperText,
  requestKey,
  initialValue,
  customValue,
  customOnChange,
  disableSearch,
  getOptionLabel = (option: AutocompleteOption) => option?.label ?? '',
  required,
  getData,
  urlParams,
  disabled,
  enabled,
  orderingCol,
  setCustomOptions = (data: any[]) => data,
  multiple,
  autoFocus,
  height,
}: AutocompleteFieldProps) {
  const [value, setValue] = useState<AutocompleteOptionValue>(
    getInitialValue(initialValue, multiple)
  );
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const { data, isFetching } = useAutocomplete({
    queryKey: [...requestKey, inputValue],
    fetchUrl,
    inputValue:
      getValidatedInput(
        value,
        inputValue,
        customValue,
        customOnChange,
        disableSearch
      ) || '',
    renderRow,
    query: urlParams,
    orderingCol,
    enabled,
  });

  useEffect(() => {
    if (!isFetching && Array.isArray(data)) {
      setOptions(setCustomOptions([{ id: null, label: 'None' }, ...data]));

      if (getData) getData(data);
    }
  }, [data, isFetching]);

  return (
    <>
      <Autocomplete
        size='small'
        fullWidth
        sx={{
          '.MuiInputBase-root': {
            height,
          },
        }}
        autoFocus={autoFocus}
        multiple={!!multiple}
        open={open}
        disabled={!!disabled}
        value={customOnChange && customValue ? customValue : value}
        onChange={(e: ChangeEvent<{}>, newValue: AutocompleteOptionValue) => {
          setValue(newValue);
          if (customOnChange) customOnChange(newValue);
        }}
        onInputChange={(e: ChangeEvent<{}>, newInputValue: string) => {
          setInputValue(newInputValue ?? '');
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={isFetching}
        autoComplete
        noOptionsText='No options'
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            required={!!required}
            error={error}
            helperText={helperText}
            slotProps={{
              input: {
                ...params.InputProps,
              },
            }}
          />
        )}
      />
    </>
  );
}
