export const ADMIN_LOGO = 'Pullstream admin';

export const COLORS = {
  primary: '#4792dd',
  light: '#f7f7f8',
  background: '#f8fbff',
  dark: '#1B1F33',
  drawerLight: '#999',
  linkActive: '#EEE',
};

export const ENCRYPTION_PASS_KEY = 'PS-ENCRYPTION-KEY';

export const COOKIE_USER_SESSION = `${process.env.NEXT_PUBLIC_APP_ENV}-be1f0e0d42b1`;

export const COOKIE_ACCESS_TOKEN = `${process.env.NEXT_PUBLIC_APP_ENV}-ec36abdba88b`;

export const COOKIE_REFRESH_TOKEN = `${process.env.NEXT_PUBLIC_APP_ENV}-8d06cfb59d0c`;

export const DURATION_UNIT_CHOICES = [
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Minutes',
    value: 'minutes',
  },
];

export const TOAST_CONTAINER_PROPS = {
  position: 'top-center',
  autoClose: 5000,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  hideProgressBar: true,
  theme: 'dark',
};

export const DEFAULT_QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};
