import _ from 'lodash';
import { useField, useFormikContext } from 'formik';
import AutocompleteField from './AutocompleteField';

interface Value {
  id: string | null;
  label: string;
}

interface FormikAutocompleteFieldProps {
  name: string;
  label?: string;
  requestKey: string[];
  fetchUrl: (options: { query: string }) => string;
  renderRow?: (data: any) => any; // Consider defining a more specific type based on actual data usage
  onChange?: (newValue: Value | Value[] | null) => void;
  disableDefaultSetValue?: boolean;
  disableSearch?: boolean;
  urlParams?: string;
  getOptionLabel?: (option: Value) => string;
  getData?: (data: any[]) => void; // Consider defining a more specific type
  setCustomOptions?: (data: any[]) => any[]; // Consider defining a more specific type
  multiple?: boolean;
  idKey?: string;
  orderingCol?: string;
  enabled?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export default function FormikAutocompleteField({
  name,
  label,
  requestKey,
  fetchUrl,
  renderRow,
  onChange,
  disableDefaultSetValue,
  disableSearch,
  getData,
  urlParams,
  getOptionLabel = (option) => option.label,
  setCustomOptions = (data) => data,
  required,
  disabled,
  enabled,
  orderingCol,
  multiple,
  idKey = 'id',
}: FormikAutocompleteFieldProps) {
  const [field, meta] = useField<Value>(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (newValue: Value | Value[] | null) => {
    if (!disableDefaultSetValue) {
      setFieldValue(name, newValue);
    }
    if (onChange) {
      onChange(newValue ?? null);
    }
  };

  // Function to process field value based on given criteria.
  // TODO: Replace any type with a more specific type later
  function processFieldValue(field: any, multiple?: boolean) {
    // Ensure field and field.value exist and field.value is an object.
    if (field && _.isObject(field.value)) {
      // Check if both id and label are strings.
      const isValidObject =
        _.isString(field.value[idKey]) && _.isString(field.value.label);

      if (isValidObject) {
        return field.value; // Return the value if it matches the criteria.
      }
    }

    // Handle the 'multiple' scenario more cleanly.
    if (multiple) {
      // If field.value is an array, return it; otherwise, return [].
      return Array.isArray(field.value) ? field.value : [];
    }

    // If not handling a 'multiple' scenario or the criteria are not met, return null.
    return null;
  }

  return (
    <AutocompleteField
      label={label || ''}
      orderingCol={orderingCol}
      required={required}
      urlParams={urlParams}
      getData={getData}
      setCustomOptions={setCustomOptions}
      disableSearch={disableSearch}
      requestKey={requestKey}
      fetchUrl={fetchUrl}
      renderRow={renderRow}
      getOptionLabel={getOptionLabel}
      enabled={enabled}
      customValue={processFieldValue(field, multiple)}
      customOnChange={handleChange}
      error={!!meta.error}
      helperText={typeof meta.error === 'string' ? meta.error : ''}
      disabled={disabled}
      multiple={multiple}
    />
  );
}
