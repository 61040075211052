import { useField } from 'formik';
import TextBox from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';

// Define a type for the custom props, extending the existing TextFieldProps
interface FormikTextProps
  extends Omit<TextFieldProps, 'onChange' | 'onBlur' | 'value' | 'error'> {
  label: string;
  afterDefaultOnChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  multiline?: boolean;
  customOnChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  customOnBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  customOnFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  hideError?: boolean;
  helperText?: string;
  name: string;
}

export default function FormikTextField({
  label,
  afterDefaultOnChange,
  multiline = false,
  customOnChange,
  customOnBlur,
  hideError = false,
  helperText,
  name,
  customOnFocus,
  ...rest
}: FormikTextProps) {
  const [field, meta] = useField(name);
  const isError = Boolean(meta?.touched && meta?.error);

  return (
    <TextBox
      {...rest}
      multiline={multiline}
      size='small'
      label={label}
      error={isError}
      name={field.name}
      value={field.value}
      autoComplete='off'
      onBlur={(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        customOnBlur ? customOnBlur(event) : field.onBlur(event);
      }}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (customOnChange) {
          customOnChange(event);
        } else {
          field.onChange(event);
        }

        if (afterDefaultOnChange) {
          afterDefaultOnChange(event);
        }
      }}
      onFocus={(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (customOnFocus) {
          customOnFocus(event);
        }
      }}
      helperText={hideError || !isError ? (helperText ?? null) : meta.error}
    />
  );
}
