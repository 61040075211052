import { Public_Sans } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { COLORS } from './constants';

export const publicSans = Public_Sans({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
});

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: COLORS.background,
    },
  },
  typography: {
    fontFamily: publicSans.style.fontFamily,
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
