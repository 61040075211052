/**
 *
 * URLs
 */
const HOST = process.env.NEXT_PUBLIC_WIKI_HOST;

export const getBooksURL = ({ query = '' } = {}) => {
  return `${HOST}/api/books/${query}`;
};

export const getChaptersURL = ({ query = '' } = {}) => {
  return `${HOST}/api/chapters/${query}`;
};
