import axios from 'axios';
import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@repo/redux/hooks';
import { selectProfile } from '@repo/redux/profileSlice';
import { getFetcherConfig } from '@repo/config/authUtils';

interface UseAutocompleteProps {
  queryKey: string[];
  inputValue: string;
  selected?: boolean;
  orderingCol?: string;
  query?: string;
  enabled?: boolean;
  fetchUrl: (options: { query: string }) => string;
  renderRow?: (row: any) => any;
}

export default function useAutocomplete({
  inputValue,
  selected,
  fetchUrl,
  orderingCol,
  query,
  queryKey,
  enabled,
  renderRow = (row) => ({ id: row?.id, label: row?.name }),
}: UseAutocompleteProps) {
  const [term, setTerm] = useState('');
  const [records, setRecords] = useState<any>(null);
  const { accessToken } = useAppSelector(selectProfile);

  const queryString = useMemo(() => {
    return (
      new URLSearchParams({
        search: term,
        autocomplete: 'true',
        page_size: '500',
        ordering: orderingCol ?? 'name',
      }).toString() + (query ?? '')
    );
  }, [term, orderingCol, query]);

  // Handle the enabled condition based on the presence of the accessToken and passed enabled prop
  const isEnabled = !!accessToken && (enabled ?? true);

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: [term, ...queryKey],
    queryFn: async () => {
      const { data } = await axios.get(
        fetchUrl({ query: `?${queryString}` }),
        getFetcherConfig({ token: accessToken })
      );
      return data;
    },
    enabled: isEnabled,
  });

  useEffect(() => {
    if (selected) return;
    const timeoutId = setTimeout(() => setTerm(inputValue), 500);
    return () => clearTimeout(timeoutId);
  }, [inputValue, selected]);

  useEffect(() => {
    if (!data) return;

    let records;

    if (Array.isArray(data?.results)) {
      records = data.results;
    } else if (Array.isArray(data)) {
      records = data;
    } else {
      records = [data];
    }

    if (renderRow) {
      setRecords(records.map(renderRow));
    } else {
      setRecords(data);
    }
  }, [data]);

  return { isPending, error, data: records, isFetching };
}
