import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export type ExecutionStatus = 'idle' | 'busy' | 'done' | 'failed';

interface OperationState {
  executionStatus: ExecutionStatus;
  editMode: boolean;
}

const initialState: OperationState = {
  executionStatus: 'idle', // idle, busy, done, or failed
  editMode: false,
};

export const operationSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    setExecutionStatus: (state, action: PayloadAction<ExecutionStatus>) => {
      state.executionStatus = action.payload;
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
  },
});

export const { setExecutionStatus, setEditMode } = operationSlice.actions;

export const selectOperations = (state: RootState) => state.operations;

export default operationSlice.reducer;
