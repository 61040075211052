import Link from 'next/link';
import MuiAppBar from '@mui/material/AppBar';
import { MenuOutlined } from '@ant-design/icons';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppSelector } from '@repo/redux/hooks';
import { selectProfile } from '@repo/redux/profileSlice';
import { selectOperations } from '@repo/redux/operationSlice';

import UserAccountMenu from '../UserAccountMenu/UserAccountMenu';
import AppBarQuickToolsMenu from '../AppBarQuickToolsMenu/AppBarQuickToolsMenu';

interface AppBarProps {
  handleDrawerToggle: () => void;
}

export default function AppBar({ handleDrawerToggle }: AppBarProps) {
  const theme = useTheme();
  const { domain, brandColor, adminLogo } = useAppSelector(selectProfile);
  const { executionStatus } = useAppSelector(selectOperations);
  const isDesktopOrTablet = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <MuiAppBar
      position='fixed'
      sx={{
        backgroundColor: brandColor ?? undefined,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
      }}
    >
      <Toolbar
        variant='dense'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerToggle}
            edge='start'
            size='small'
            sx={{ mr: 1, display: 'block' }}
          >
            <MenuOutlined />
          </IconButton>

          <Link
            href={`${domain}/`}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <Typography variant='h6' fontWeight={600} noWrap component='div'>
              {adminLogo}
            </Typography>
          </Link>

          <AppBarQuickToolsMenu domain={domain} />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {executionStatus === 'failed' && isDesktopOrTablet ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              <WarningAmberIcon fontSize='small' sx={{ color: '#e2e2e2' }} />
              <Typography sx={{ ml: 1, color: '#e2e2e2' }}>
                Failed to save
              </Typography>
            </Box>
          ) : null}

          {executionStatus === 'busy' && isDesktopOrTablet ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              <CachedIcon fontSize='small' sx={{ color: '#e2e2e2' }} />
              <Typography sx={{ ml: 1, color: '#e2e2e2' }}>
                Saving...
              </Typography>
            </Box>
          ) : null}

          {(executionStatus === 'idle' || executionStatus === 'done') &&
          isDesktopOrTablet ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              {executionStatus === 'done' ? (
                <>
                  <CloudDoneOutlinedIcon
                    fontSize='small'
                    sx={{ color: '#e2e2e2' }}
                  />
                  <Typography sx={{ ml: 1, color: '#e2e2e2' }}>
                    Saved
                  </Typography>
                </>
              ) : null}
            </Box>
          ) : null}

          <UserAccountMenu />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}
