import * as muiIcons from '@mui/icons-material';
import drawerLinksJSON from './drawerLinksData.json';

export type MenuItem = {
  id: string;
  href: string;
  label: string;
  roles?: string[];
  prefix?: string;
  icon?: string;
  children?: MenuItem[];
  unstable?: boolean;
  createdByCompute?: boolean;
  getIcon?: () => React.ReactNode;
};

const defaultIcon = muiIcons['ErrorOutlineOutlined'];

const DRAWER_LINKS: MenuItem[] = drawerLinksJSON.map((menuItem: MenuItem) => {
  const { icon, ...item } = menuItem;

  return {
    ...item,
    getIcon: () => {
      const iconKey = icon as keyof typeof muiIcons;
      const IconComponent = muiIcons[iconKey] ?? defaultIcon;
      return <IconComponent />;
    },
  };
});

export function getDrawerLinks(roles: string[]) {
  // Early return for LMS Student case
  if (roles.includes('LMS Student')) {
    return { live: [], unstable: [], createdByCompute: [] };
  }

  // Refactored the reduce function to avoid redundancy and make it cleaner
  return DRAWER_LINKS.reduce(
    (acc, curr) => {
      // Check if the current menu item matches the role
      if (curr.roles && roles.some((role) => curr.roles!.includes(role))) {
        if (curr.createdByCompute) {
          acc.createdByCompute.push(curr);
        } else if (curr.unstable) {
          acc.unstable.push(curr);
        } else {
          acc.live.push(curr);
        }
      }
      return acc;
    },
    {
      live: [] as MenuItem[],
      unstable: [] as MenuItem[],
      createdByCompute: [] as MenuItem[],
    }
  );
}
