import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import Link from 'next/link';

interface DrawerItemProps {
  children?: React.ReactNode;
  getIcon?: () => React.ReactNode;
  label: string;
  href: string;
  expandable?: boolean;
  initiallyExpanded?: boolean;
  depth?: number; // Added depth prop to handle indentation based on level
}

export default function DrawerItem({
  label,
  getIcon,
  children,
  expandable,
  href,
  initiallyExpanded,
  depth = 0, // Default depth is 0 for top-level items
}: DrawerItemProps) {
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const getIndentation = (depth: number) => {
    // Level 2 and onward dropdowns
    if (depth > 4) {
      return 16;
    }

    // Level 1 dropdown
    if (depth > 1) {
      return 15;
    }

    // Global menu
    return 46 * depth;
  };

  // Calculate indentation based on depth
  const indent = getIndentation(depth);

  const handleClick = () => {
    if (expandable) {
      setExpanded((e) => !e);
    }
  };

  return (
    <Box sx={{ pl: `${indent}px` }}>
      <Link href={href} style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            py: 0,
            minHeight: 32,
            pl: depth ? '12px' : undefined,
            borderLeft: depth ? '1px solid #ddd' : undefined,
          }}
        >
          {getIcon ? (
            <ListItemIcon sx={{ minWidth: 0, mr: '16px' }}>
              {getIcon()}
            </ListItemIcon>
          ) : null}

          <ListItemText
            primary={label}
            primaryTypographyProps={{
              fontSize: 14,
              fontWeight: 'medium',
              color: depth ? '#576375' : undefined,
            }}
          />

          {expandable ? (
            <ListItemIcon sx={{ minWidth: 0 }}>
              {expanded ? (
                <DownOutlined style={{ fontSize: '13px' }} />
              ) : (
                <RightOutlined style={{ fontSize: '13px' }} />
              )}
            </ListItemIcon>
          ) : null}
        </ListItemButton>
      </Link>

      {expandable ? (
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Box sx={{ pb: 1 }}>{children}</Box>
        </Collapse>
      ) : null}
    </Box>
  );
}
