import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { ADMIN_LOGO, COLORS } from '@repo/config/constants';

// Define a type for the slice state
interface ProfileState {
  accessToken: string;
  refreshToken: string;
  domain: string;
  userId: string;
  username: string;
  email: string;
  isSuperUser: boolean;
  personId: string;
  employeeId: string;
  employeeSupervisorId: string;
  employeeSupervisorName: string;
  client: string;
  roleNames: string[];
  adminLogo: string | null;
  favicon: string | null;
  brandColor: string;
}

// Define the initial state using that type
const initialState: ProfileState = {
  accessToken: '',
  refreshToken: '',
  domain: '',
  userId: '',
  username: '',
  email: '',
  isSuperUser: false,
  personId: '',
  employeeId: '',
  employeeSupervisorId: '',
  employeeSupervisorName: '',
  client: '',
  roleNames: [],
  adminLogo: ADMIN_LOGO,
  favicon: '',
  brandColor: COLORS.primary,
};

export const profileSlice = createSlice({
  name: 'profile',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileState>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.domain = action.payload.domain;
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.isSuperUser = action.payload.isSuperUser;
      state.personId = action.payload.personId;
      state.employeeId = action.payload.employeeId;
      state.employeeSupervisorId = action.payload.employeeSupervisorId;
      state.employeeSupervisorName = action.payload.employeeSupervisorName;
      state.client = action.payload.client;
      state.roleNames = action.payload.roleNames;

      if (action.payload.adminLogo) {
        state.adminLogo = action.payload.adminLogo;
      }

      if (action.payload.favicon) {
        state.favicon = action.payload.favicon;
      }

      if (action.payload.brandColor) {
        state.brandColor = action.payload.brandColor;
      }
    },
  },
});

export const { setProfile } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
